<template>
  <div style="padding: 20px;">
    <Confirm ref="Confirm"></Confirm>
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-12">
            <h4 class="mb-2">스테이킹 추가보상 목록</h4>
          </div><!-- /.col -->
        </div><!-- /.row -->
        <div class="row mb-2">
          <div class="col-sm-12">
            <div class="row justify-content-between">
              <div class="row">
                <h5 class="mr-2 mt-sm-2 ml-2">필터</h5>
                <select class="form-control w-auto mr-1" v-model="selectedSearchType">
                  <option value="STAKING_PLAN_NAME">스테이킹 플랜</option>
                </select>
                <input type="text" class="form-control w-auto mr-1" placeholder="검색어" v-model="searchKeyword"
                       @keyup.enter="getList(1, selectedState)">
                <button class="btn btn-primary" @click="getList(1, selectedState)">검색</button>
              </div>
              <div>
                <button class="badge btn btn-default text-white p-2 pr-3 pl-3 border-secondary" style="border-radius: 0"
                        :class="{'btn-warning': selectedState === 'ALL'}" @click="searchStateBtn('ALL')">
                  전체
                </button>
                <button class="badge btn btn-default text-white p-2 pr-3 pl-3 border-secondary" style="border-radius: 0"
                        :class="{'btn-warning': selectedState === 'LOCKED'}" @click="searchStateBtn('LOCKED')">
                  대기중
                </button>
                <button class="badge btn btn-default text-white p-2 pr-3 pl-3 border-secondary" style="border-radius: 0"
                        :class="{'btn-warning': selectedState === 'PROCESSING'}" @click="searchStateBtn('PROCESSING')">
                  진행중
                </button>
                <button class="badge btn btn-default text-white p-2 pr-3 pl-3 border-secondary" style="border-radius: 0"
                        :class="{'btn-warning': selectedState === 'DONE'}" @click="searchStateBtn('DONE')">
                  종료
                </button>
              </div>
            </div>
          </div>
        </div>
      </div><!-- /.container-fluid -->
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-body">
                <table class="table table-bordered" id="excelData">
                  <thead>
                  <tr>
                    <th>
                      <input type="checkbox" disabled v-if="!selectClose">
                      <input type="checkbox" v-model="checkedAllClose" v-if="selectClose">
                    </th>
                    <th>No</th>
                    <th>스테이킹 추가보상 플랜</th>
                    <th>참여기간</th>
                    <th>스테이킹 기간</th>
                    <th>참여 최소수량</th>
                    <th>참여 최대수량</th>
                    <th>총보상금액(원)</th>
                    <th>중복참여</th>
                    <th>상태</th>
                    <th>상세보기</th>
                  </tr>
                  </thead>
                  <tbody v-if="items.length > 0">
                  <tr v-for="(item, index) in items" :key="item.stakingPlanIdx">
                    <td class="text-center">
                      <input type="checkbox" disabled v-if="!selectClose">
                      <input type="checkbox" v-if="selectClose && item.status === 'PROCESSING'"
                             :id="'chk'+item.stakingPlanIdx" v-model="closeChk" :value="item.stakingPlanIdx">
                      <input type="checkbox" disabled v-if="selectClose && item.status !== 'PROCESSING'">
                    </td>
                    <td style="text-align: center">{{ index + 1 + (page - 1) * 10 }}</td>
                    <td>{{ item.stakingPlanName }}</td>
                    <td class="text-center">
                      {{ item.participateStartDate | moment('YYYY-MM-DD') }} ~
                      {{ item.participateEndDate | moment('YYYY-MM-DD') }}
                    </td>
                    <td class="text-center">
                      {{ item.stakingStartDate | moment('YYYY-MM-DD') }} ~
                      {{ item.stakingExpiryDate | moment('YYYY-MM-DD') }}
                    </td>
                    <td class="text-right">{{ $numberWithCommas(item.minQuantity) }}</td>
                    <td class="text-right">{{ $numberWithCommas(item.maxQuantity) }}</td>
                    <td class="text-right">{{ $numberWithCommas(item.rewardPoint) }}</td>
                    <td class="text-center">
                      <span v-if="item.participateDuplicate">가능</span>
                      <span v-else>불가능</span>
                    </td>
                    <td class="text-center"
                        :class="{ 'text-orange':item.status === 'PROCESSING','text-primary':item.status === 'LOCKED' }">
                      {{ trans(item.status) }}
                    </td>
                    <td class="text-center">
                      <span class="badge btn btn-outline-warning" @click="goToDetail(item.stakingPlanIdx)">상세보기</span>
                    </td>
                  </tr>
                  </tbody>
                  <tbody v-else>
                  <tr>
                    <td colspan="11" style="text-align: center;">관련된 내역이 없습니다.</td>
                  </tr>
                  </tbody>
                </table>
              </div><!-- ./card-body -->
              <div class="row justify-content-end mr-3 mb-3">
                <button class="btn btn-sm btn-secondary text-white mr-2" v-if="!selectClose"
                        @click="selectClose = true;">스테이킹 종료 선택
                </button>
                <button class="btn btn-sm btn-warning text-white mr-2" v-else @click="closeStaking()">스테이킹 종료</button>
              </div>
              <div class="card-footer clearfix text-center row justify-content-center">
                <div></div>
                <pagination ref="Pagination"></pagination>
              </div>
            </div><!-- /.card -->
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div>
    </section>
  </div>
</template>

<script>
import Pagination from "../common/Pagination"
import Confirm from "@/components/common/Confirm";

export default {
  name: "StakingPlanRewardList",

  components: {
    Pagination,
    Confirm
  },

  data() {
    return {
      selectedState: this.$route.params.ss ? this.$route.params.ss : 'ALL',
      selectedSearchType: this.$route.params.st ? this.$route.params.st : 'STAKING_PLAN_NAME',
      searchKeyword: this.$route.params.sk ? this.$route.params.sk : '',
      size: '10',
      page: this.$route.params.page ? this.$route.params.page : 1,
      total: 0,
      items: [],
      listState: false,
      selectClose: false,
      closeChk: [],
      closeList: [],
    }
  },

  created() {
    this.$root.$emit(this.$EVENT_LNB_CHANGE, 'stakingPlan', this.$options.name)

    let page = this.$route.query.page
    if (typeof (page) === 'undefined') {
      page = 1
    }

    let size = this.$route.query.size
    if (typeof (size) !== 'undefined') {
      this.size = size
    }

    let ss = this.$route.query.ss
    if (typeof (ss) !== 'undefined') {
      this.selectedState = ss
    }

    let st = this.$route.query.st
    if (typeof (st) !== 'undefined') {
      this.selectedSearchType = st
    }

    let sk = this.$route.query.sk
    if (typeof (sk) !== 'undefined') {
      this.searchKeyword = sk
    }

    this.getList(this.$route.query.page, this.$route.query.ss)
  },

  methods: {
    closeStaking() {
      this.successList = []
      this.failList = []
      let formData = new FormData();
      if (this.closeChk.length === 0) {
        this.selectClose = false
      } else {
        this.$refs.Confirm.showDialog(`총 ${this.closeChk.length}개의 스테이킹 플랜을 종료 하시겠습니까?<br>스테이킹 플랜 종료 시 복구가 불가능합니다.`, () => {
          this.closeChk.forEach((item) => {
            formData.append('planIdxes[]', item)
          })
          this.$put(this.$STAKING_CLOSE_PLAN, 'close_staking_plan', formData, true, () => {
            this.selectClose = false
            this.closeChk = []
            this.getList(this.$route.query.page, this.$route.query.ss)
            this.notify('success', '스테이킹이 종료되었습니다.')
          }, (result) => {
            this.httpError(result)
          })
        }, '스테이킹을 종료하겠습니다.')
      }
    },

    searchStateBtn(state) {
      this.$router.push({
        query: {page: 1, size: this.size, ss: state, st: this.selectedSearchType},
      });
      this.selectedState = state
      this.getList(1, state)
    },

    trans(value) {
      if (value === 'PROCESSING') {
        return '진행중'
      } else if (value === 'LOCKED') {
        return '대기중'
      } else if (value === 'DONE') {
        return '종료'
      }
    },

    goToDetail(planId) {
      this.$router.push({
        name: 'StakingPlanDetail',
        params: {planId: planId}
      })
    },

    getList(page, state) {
      let params = new URLSearchParams()
      if (typeof page === 'undefined') page = 1
      if (page === 1) this.page = 1
      params.append('page', page)
      params.append('limit', this.size)
      if (state) {
        if (state === 'ALL') {
          this.selectedState = state
        } else {
          this.selectedState = state
          params.append('status', this.selectedState)
        }
      }
      params.append('criteria', this.selectedSearchType)
      params.append('keyword', this.searchKeyword.trim())
      this.$get(this.$STAKING_PLAN_EXTRA + '?' + params.toString(), this.$options.name + '_getList', true, (result) => {
        this.page = page
        this.total = result.total

        try {
          this.$refs.Pagination.init(this.$options.name, {
            ss: this.selectedState,
            st: this.selectedSearchType,
            sk: this.searchKeyword,
          }, Number(this.page), this.total, Number(this.size))
        } catch (e) {
          console.log(e)
        }
        this.$router.replace({
          query: {
            ss: this.selectedState,
            st: this.selectedSearchType,
            sk: this.searchKeyword,
            page: this.page,
            size: this.size,
          },
        });
        this.items = result.data
        this.closeChk = []
        this.closeList = []
        this.selectClose = false
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i].status === 'PROCESSING') {
            this.closeList.push(this.items[i].stakingPlanIdx)
          }
        }
      }, (result) => {
        this.httpError(result)
      })
    },
  },

  computed: {
    checkedAllClose: {
      get: function () {
        if (this.closeChk.length <= 0) {
          return
        }
        return this.closeList.length === this.closeChk.length;
      },
      set: function (e) {
        this.closeChk = e ? this.closeList : [];
      },
    },
  },

  watch: {
    '$route.query.page'() {
      this.getList(this.$route.query.page, this.$route.query.ss)
    },
  },
}
</script>

<style scoped>

</style>