<template>
  <aside class="main-sidebar sidebar-dark-primary elevation-4">
    <router-link :to="{ name: 'Dashboard' }" class="brand-link">
      <img src="dist/img/ico_buff.svg" alt="Logo" class="brand-image img-circle">
      <span class="brand-text font-weight-bold">{{ getTitle() }}</span>
    </router-link>
    <div class="sidebar">
      <nav class="mt-2 pt-3">
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
          <li v-for="(navItem, index) in lnb" :key="index" class="nav-item"
              v-bind:class="{ 'menu-open' : selectedCode === navItem.code }">
            <a class="nav-link" v-bind:class="{ active : selectedCode === navItem.code}">
              <i class="nav-icon" v-bind:class="navItem.icon"></i>
              <p>
                {{ navItem.title }}
                <i class="right fas fa-angle-left"></i>
              </p>
            </a>
            <ul class="nav nav-treeview" v-show="selectedCode === navItem.code">
              <li v-for="(subItem, subIndex) in navItem.sub" :key="subIndex" class="nav-item">
                <router-link :to="{ name: subItem.link }" class="nav-link"
                             v-bind:class="{ active : selectedSubCode === subItem.subCode}">
                  <p>{{ subItem.subTitle }}</p>
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </div>
  </aside>
</template>

<script>
export default {
  data() {
    return {
      selectedCode: null,
      selectedSubCode: null,
      lnb: [
        {
          'icon': 'fas fa-user',
          'title': '사용자',
          'code': 'user',
          'sub': [
            {
              'subTitle': '사용자 목록',
              'subCode': 'UserList',
              'link': 'UserList'
            },
            {
              'subTitle': '출금 화이트리스트',
              'subCode': 'WhiteList',
              'link': 'WhiteList'
            },
          ]
        },
        {
          'icon': 'fas fa-tasks',
          'title': '락업 플랜',
          'code': 'lockupPlan',
          'sub': [
            {
              'subTitle': '락업 플랜 등록',
              'subCode': 'LockupPlanRegister',
              'link': 'LockupPlanRegister',
            },
            {
              'subTitle': '락업 플랜 목록',
              'subCode': 'LockupPlanList',
              'link': 'LockupPlanList',
            },
            {
              'subTitle': '락업 참여자',
              'subCode': 'LockupUser',
              'link': 'LockupUser',
            },
          ]
        },
        {
          'icon': 'fas fa-tasks',
          'title': '스테이킹 플랜',
          'code': 'stakingPlan',
          'sub': [
            {
              'subTitle': '스테이킹 플랜 등록',
              'subCode': 'StakingPlanRegister',
              'link': 'StakingPlanRegister',
            },
            {
              'subTitle': '스테이킹 고정이자 목록',
              'subCode': 'StakingPlanFixedList',
              'link': 'StakingPlanFixedList',
            },
            {
              'subTitle': '스테이킹 추가보상 목록',
              'subCode': 'StakingPlanRewardList',
              'link': 'StakingPlanRewardList',
            },
            {
              'subTitle': '고정이자 참여자',
              'subCode': 'StakingFixedUser',
              'link': 'StakingFixedUser',
            },
            {
              'subTitle': '추가보상 참여자',
              'subCode': 'StakingRewardUser',
              'link': 'StakingRewardUser',
            },
          ]
        },
        {
          'icon': 'fas fa-align-left',
          'title': '화폐',
          'code': 'currency',
          'sub': [
            {
              'subTitle': '내부 출금',
              'subCode': 'CurrencyInternal',
              'link': 'CurrencyInternal',
            },
            {
              'subTitle': '외부 출금',
              'subCode': 'CurrencyExternal',
              'link': 'CurrencyExternal',
            },
            {
              'subTitle': '출력 설정',
              'subCode': 'CurrencyOutput',
              'link': 'CurrencyOutput',
            },
          ]
        },
        {
          'icon': 'fas fa-clipboard-list',
          'title': '입출금',
          'code': 'wallet',
          'sub': [
            {
              'subTitle': '입출금 내역',
              'subCode': 'WalletList',
              'link': 'WalletList',
            },
            {
              'subTitle': 'API 입출금 내역',
              'subCode': 'WalletHistoryBunny',
              'link': 'WalletHistoryBunny',
            },
            {
              'subTitle': '일괄 직권송금',
              'subCode': 'BatchDirect',
              'link': 'BatchDirect',
            },
          ]
        },
        {
          'icon': 'fas fa-clipboard-list',
          'title': '설정',
          'code': 'setting',
          'sub': [
            {
              'subTitle': '스테이킹 이용약관',
              'subCode': 'TermsList',
              'link': 'TermsList',
            },
          ]
        },
      ],
    }
  },

  created() {
    this.$root.$on(this.$EVENT_LNB_CHANGE, (_code, _subCode) => {
      this.selectedCode = _code
      this.selectedSubCode = _subCode
    })
  }
}
</script>

<style>
.brand-link:hover {
  color: rgba(255, 255, 255, .8) !important;
}
</style>
