<template>
  <div style="padding: 20px;">
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-12">
            <h4 class="mb-2">일괄 직권송금</h4>
          </div><!-- /.col -->
        </div><!-- /.row -->
        <div class="row mb-2">
          <div class="col-sm-12">
            <div class="row justify-content-end">
              <div>
                <button type="button" class="btn btn-sm btn-warning text-white mr-2" @click="getSample">양식다운</button>
                <input type="file" @change="uploadFile" id="file" name="file" style="display:none;">
                <input type="button" class="input_file" id="loadFileXml" value="업로드"
                       onclick="document.getElementById('file').click();"/>
              </div>
            </div>
          </div>
        </div>
      </div><!-- /.container-fluid -->
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-body">
                <table class="table table-bordered" id="excelData">
                  <thead>
                  <tr>
                    <th>NO</th>
                    <th>등록일</th>
                    <th>직권 송금 사용자</th>
                    <th>직권 송금 성공 사용자</th>
                    <th>직권 송금 실패 사용자</th>
                    <th>엑셀 다운로드</th>
                  </tr>
                  </thead>
                  <tbody v-if="items.length > 0">
                  <tr v-for="(item, index) in items" :key="item.lockupPlanIdx">
                    <td style="text-align: center">{{ index + 1 + (page - 1) * 10 }}</td>
                    <td style="text-align: center">{{ item.createdAt | moment('YYYY-MM-DD HH:mm:ss') }}</td>
                    <td style="text-align: right">{{ item.totalCount }}</td>
                    <td style="text-align: right">{{ item.successCount }}</td>
                    <td style="text-align: right">{{ item.failCount }}</td>
                    <td style="text-align: center">
                      <button type="button" class="badge btn btn-outline-warning" @click="getFailList(item.idx)"
                              :disabled="item.failCount < 1">엑셀 다운로드
                      </button>
                    </td>
                  </tr>
                  </tbody>
                  <tbody v-else>
                  <tr>
                    <td colspan="10" style="text-align: center;">관련된 내역이 없습니다.</td>
                  </tr>
                  </tbody>
                </table>
              </div><!-- ./card-body -->
              <div class="card-footer clearfix text-center row justify-content-center">
                <pagination ref="Pagination"></pagination>
              </div>
            </div><!-- /.card -->
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div>
    </section>
  </div>
</template>

<script>
import Pagination from "@/components/common/Pagination";

export default {
  name: "BatchDirect",

  components: {
    Pagination,
  },

  data() {
    return {
      size: '10',
      page: 0,
      total: 0,
      items: [],
      sample: '',
    }
  },

  created() {
    this.$root.$emit(this.$EVENT_LNB_CHANGE, 'wallet', this.$options.name)

    let page = this.$route.query.page
    if (typeof (page) === 'undefined') {
      page = 1
    }

    let size = this.$route.query.size
    if (typeof (size) !== 'undefined') {
      this.size = size
    }

    this.getList(page)
  },

  methods: {
    uploadFile(e) {
      const file = e.target.files;
      let reg = /(.*?)\.(xlsx|XLSX|xls|XLS)$/;
      if (!file[0].name.match(reg)) {
        this.notify('error', '엑셀 파일만 업로드 가능합니다.')
        return
      }
      let formData = new FormData()
      formData.append('file', file[0])
      this.$post(this.$BATCH_DIRECT, this.$options.name + '_postData', formData, true, () => {
        this.getList(1)
        this.notify('success', '파일 업로드를 완료했습니다.')
      }, (result) => {
        this.httpError(result)
      })
    },

    getFailList(idx) {
      this.$download(`${this.$BATCH_DIRECT}/${idx}`, this.$options.name + '_getData', true, (result) => {
        const url = window.URL.createObjectURL(new Blob([result.data], {type: result.headers['content-type']}));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'test.xlsx');
        document.body.appendChild(link);
        link.click();
        this.notify('success', '파일을 다운로드 중입니다.')
      }, (result) => {
        this.httpError(result)
      })
    },

    getSample() {
      this.$download(this.$BATCH_DIRECT_SAMPLE, this.$options.name + '_getData', true, (result) => {
        const url = window.URL.createObjectURL(new Blob([result.data], {type: result.headers['content-type']}));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'test.xlsx');
        document.body.appendChild(link);
        link.click();
        this.notify('success', '양식을 다운로드 중입니다.')
      }, (result) => {
        this.httpError(result)
      })
    },

    getList(page) {
      this.$get(`${this.$BATCH_DIRECT}?page=${page}&limit=${this.size}`, this.$options.name + '_getList', true, (result) => {
        this.page = page
        this.total = result.total

        try {
          this.$refs.Pagination.init(this.$options.name, {}, Number(this.page), this.total, Number(this.size))
        } catch (e) {
          console.log(e)
        }

        this.items = result.data

      }, (result) => {
        this.httpError(result)
      })
    },
  },

  watch: {
    '$route.query.page'() {
      this.getList(this.$route.query.page)
    },
  },
}
</script>
<style scoped>

.input_file {
  padding: 4px 16px;
  height: 31px;
  background: #3F6791;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  color: #fff;
  font-size: 14px;
}

.input_file {
  background: #3F6791;
}

</style>