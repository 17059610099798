<template>
  <div style="padding: 20px;">
    <Confirm ref="Confirm"></Confirm>
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-4">
          <div class="col-sm-12">
            <h4 class="m-0">사용자 락업 플랜 추가</h4>
          </div>
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
              </div><!-- /.card-header -->
              <div class="card-body">
                <div class="row justify-content-center align-items-center mb-2">
                  <div class="col-sm-1 text-right">
                    <strong>아이디</strong>
                  </div>
                  <div class="col-sm-5">
                    <input type="text" class="form-control" style="background: #6c757d;" :value="id" disabled/>
                  </div>
                  <div class="col-sm-1">
                  </div>
                </div>
                <div class="row justify-content-center align-items-center mb-2">
                  <div class="col-sm-1 text-right">
                    <strong>락업 수량 *</strong>
                  </div>
                  <div class="col-sm-5">
                    <input type="number" class="form-control" min="0" v-model.number="amtToLockup"
                           @input="handleInput"/>
                  </div>
                  <div class="col-sm-1">
                  </div>
                </div>
                <div class="row justify-content-center align-items-center mb-2">
                  <div class="col-sm-1 text-right">
                    <strong>보상 비율</strong>
                  </div>
                  <div class="col-sm-5 position-relative">
                    <span class="form-control" style="background: #6c757d;">{{ $setDecimal(planDetail.rewardP, 10000) }} %</span>
                    <div class="input_placeholder">
                      <span>사용자 락업 수량의 일정 비율만큼 보상을 지급합니다.</span>
                    </div>
                  </div>
                  <div class="col-sm-1">
                  </div>
                </div>
                <div class="row justify-content-center align-items-center mb-2">
                  <div class="col-sm-1 text-right">
                    <strong>지급 비율</strong>
                  </div>
                  <div class="col-sm-5 position-relative">
                    <span class="form-control" style="background: #6c757d;">{{ $setDecimal(planDetail.shareP, 10000) }} %</span>
                    <div class="input_placeholder">
                      <span>락업 종료 시 설정된 비율에 따라 나눠 지급됩니다.</span>
                    </div>
                  </div>
                  <div class="col-sm-1">
                  </div>
                </div>
                <div class="row justify-content-center align-items-center mb-2">
                  <div class="col-sm-1 text-right">
                    <strong>락업 플랜 *</strong>
                  </div>
                  <div class="col-sm-2">
                    <select class="form-control" v-model="selectedPlanIdx" @change="selectLockupPlan($event)">
                      <option v-for="lockupPlan in lockupPlans" :key="lockupPlan.lockupPlanIdx"
                              :value="lockupPlan.lockupPlanIdx">{{ lockupPlan.name }}
                      </option>
                    </select>
                  </div>
                  <div class="col-sm-1 text-right">
                    <strong>락업기간</strong>
                  </div>
                  <div class="col-sm-2">
                    <span class="form-control" style="background: #6c757d;">{{ planDetail.period }} 일</span>
                  </div>
                  <div class="col-sm-1">
                  </div>
                </div>
                <div class="row justify-content-center align-items-center mb-2">
                  <div class="col-sm-1 text-right">
                    <strong>락업시작 *</strong>
                  </div>
                  <div class="col-sm-5">
                    <input type="date" class="form-control" v-model="selectedDate"/>
                  </div>
                  <div class="col-sm-1">
                  </div>
                </div>
              </div><!-- ./card-body -->
              <div class="card-footer clearfix text-center row justify-content-center">
                <div class="col-sm-5">
                  <button type="button" class="btn btn-primary mr-1" @click="openConfirm">등록</button>
                  <button type="button" class="btn btn-secondary" @click="$router.push({ name : 'UserList'})">목록
                  </button>
                </div>
              </div>
            </div><!-- /.card -->
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div>
    </section>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-body">
                <table class="table table-bordered" id="excelData">
                  <thead>
                  <tr>
                    <th>
                      <input type="checkbox" disabled v-if="!selectSuspend && !selectCancel">
                      <input type="checkbox" v-model="checkedAllProcessing" v-if="selectSuspend">
                      <input type="checkbox" v-model="checkedAllLock" v-if="selectCancel">
                    </th>
                    <th>락업 플랜</th>
                    <th>락업 기간</th>
                    <th>락업 수량</th>
                    <th>보상 비율</th>
                    <th>지급 수량 (락업+보상)</th>
                    <th>지급 비율</th>
                    <th>상태</th>
                  </tr>
                  </thead>
                  <tbody v-if="items.length > 0">
                  <tr v-for="(item, index) in items" :key="index">
                    <td class="text-center">
                      <input type="checkbox" disabled v-if="!selectSuspend && !selectCancel">
                      <input type="checkbox"
                             v-if="selectSuspend && item.status === 'PROCESSING'"
                             :id="'chk'+item.lockupIdx" v-model="suspendChk" :value="item.lockupIdx">
                      <input type="checkbox" disabled v-if="selectSuspend && item.status !== 'PROCESSING'">
                      <input type="checkbox"
                             v-if="selectCancel && item.status === 'LOCK'"
                             :id="'chk'+item.lockupIdx" v-model="cancelChk" :value="item.lockupIdx">
                      <input type="checkbox" disabled v-if="selectCancel && item.status !== 'LOCK'">
                    </td>
                    <td>{{ item.lockupPlanName }}</td>
                    <td style="text-align: center">{{ item.lockupStartDate | moment('YYYY-MM-DD') }} ~
                      {{ item.lockupExpiryDate | moment('YYYY-MM-DD') }}
                    </td>
                    <td style="text-align: right">{{ $numberWithCommasAndDecimal(item.lockupAmount) }}</td>
                    <td style="text-align: right">{{ $setDecimal(item.rewardRate, 10000) }} %</td>
                    <td style="text-align: right">
                      {{ $numberWithCommasAndDecimal(parseFloat(item.lockupAmount) + parseFloat(item.rewardAmount)) }}
                    </td>
                    <td style="text-align: right">{{ $setDecimal(100 / item.lockupRatio, 10000) }} %</td>
                    <td style="text-align: center">
                      <span :class="{'text-orange':item.status === 'LOCK'||item.status === 'PROCESSING'}">
                        {{ trans(item.status) }}
                      </span>
                    </td>
                  </tr>
                  </tbody>
                  <tbody v-else>
                  <tr>
                    <td colspan="10" style="text-align: center;">관련된 내역이 없습니다.</td>
                  </tr>
                  </tbody>
                </table>
              </div><!-- ./card-body -->
              <div class="row justify-content-end mr-3 mb-3">
                <button class="btn btn-sm btn-secondary text-white mr-2" v-if="!selectSuspend"
                        @click="selectSuspend = true; selectCancel = false">지급 중단 선택
                </button>
                <button class="btn btn-sm btn-warning text-white mr-2" v-else @click="setLockupStatus('SUSPEND')">
                  지급
                  중단
                </button>
                <button class="btn btn-sm btn-secondary text-white mr-2" v-if="!selectCancel"
                        @click="selectCancel = true; selectSuspend = false">락업 종료 선택
                </button>
                <button class="btn btn-sm btn-warning text-white mr-2" v-else @click="setLockupStatus('LOCK')">
                  락업 종료
                </button>
              </div>
              <div class="card-footer clearfix text-center row justify-content-center">
                <div></div>
                <pagination ref="Pagination"></pagination>
              </div>
            </div><!-- /.card -->
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div>
    </section>
    <transition name="modal" v-if="listState">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="modal-header">
              <h3 slot="header">
                <i class="fas fa-times close-modal-btn" style="cursor: pointer;" @click="listState=false"></i>
              </h3>
            </div>
            <div class="modal-body">
              <!--            <slot name="body">default body</slot>-->
              <div>
                <h4>요청 성공 : </h4>
                <span v-for="(list, index) in successList" :key="index" style="margin-right: 10px">
                  {{ list.lockupPlanName }}
                <span v-if="index+1 < successList.length"> / </span></span>
              </div>
              <div class="pt-4" v-if="failList.length > 0">
                <h4>요청 실패 : </h4>
                <span v-for="(list, index) in failList" :key="index" style="margin-right: 10px">
                  {{ list.lockupPlanName }}
                <span v-if="index+1 < failList.length"> / </span></span>
              </div>
            </div>
            <div class="modal-footer">
              <slot name="footer">
                <button class="modal-default-button mr-2" @click="listState=false">
                  확인
                </button>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Big from "big-js"
import Confirm from "@/components/common/Confirm";
import Pagination from "../common/Pagination"

export default {
  name: 'UserAddLockupPlan',

  components: {
    Pagination,
    Confirm,
  },

  data() {
    return {
      userIdx: this.$route.params.userIdx,
      id: '',
      amtToLockup: 0,
      lockupPlans: [],
      selectedPlanIdx: 0,
      selectedRatio: 0,
      selectedDate: '',
      shareAmount: 0,
      amtToReward: 0,
      rewardShareAmount: 0,
      size: '5',
      page: this.$route.params.page ? this.$route.params.page : 1,
      total: 0,
      items: [],
      planDetail: [],
      selectCancel: false,
      selectSuspend: false,
      cancelChk: [],
      suspendChk: [],
      processingList: [],
      lockList: [],
      listState: false,
      successList: [],
      failList: []
    }
  },

  created() {
    if (this.userIdx === undefined) {
      this.$router.back()
      return
    }

    this.$get(`${this.$LOCKUP_PLAN_LIST}?page=1&limit=100`, this.$options.name + 'getLockupPlanList', true, (result) => {
      this.lockupPlans = result.data
      this.initValue()
    }, (result) => {
      console.log(result)
    })

    this.selectedDate = this.$dateFormat(new Date())

    let page = this.$route.query.page
    if (typeof (page) === 'undefined') {
      page = 1
    }

    let size = this.$route.query.size
    if (typeof (size) !== 'undefined') {
      this.size = size
    }

    this.getUserDetail()
    this.getList(page)
  },

  methods: {
    handleInput(e) {
      let stringValue = e.target.value.toString()
      let regex = /^\d*(\.\d{1,4})?$/
      if (!stringValue.match(regex) && this.amtToLockup !== '') {
        this.amtToLockup = this.$setDecimal(this.amtToLockup, 10000)
      }
    },

    getUserDetail() {
      this.$get(`${this.$USER_LIST}/${this.userIdx}`, 'get_user_detail', true, (result) => {
        this.id = result.data[0].email
      })
    },

    setLockupStatus(type) {
      this.successList = []
      this.failList = []
      let formData = new FormData();
      // 지급중단
      if (type === 'SUSPEND') {
        if (this.suspendChk.length === 0) {
          this.selectSuspend = false
        } else {
          this.$refs.Confirm.showDialog(`총 ${this.suspendChk.length}개의 락업 지급을 중단 하시겠습니까?<br>지급 중단 시 복구가 불가능합니다.`, () => {
            this.suspendChk.forEach((item) => {
              formData.append('lockupIdxList[]', item)
            })
            this.$post(this.$LOCKUP_PLAN_SUSPEND, 'lockup_suspend', formData, true, (result) => {
              this.selectSuspend = false
              this.suspendChk = []
              let resultData = result.data
              for (let i = 0; i < resultData.length; i++) {
                if (resultData[i].result === 'success') {
                  let findData = this.items.find(e => e.lockupIdx === resultData[i].lockUpIdx)
                  this.successList.push(findData)
                } else {
                  let findData2 = this.items.find(e => e.lockupIdx === resultData[i].lockUpIdx)
                  this.failList.push(findData2)
                }
              }
              this.listState = true
              let page = this.$route.query.page
              if (typeof (page) === 'undefined') {
                page = 1
              }
              this.getList(page)
              this.notify('success', '락업 지급이 중단되었습니다.')
            })
          }, '락업 지급을 중단하겠습니다.')
        }

        // 락업 종료
      } else if (type === 'LOCK') {
        if (this.cancelChk.length === 0) {
          this.selectCancel = false
        } else {
          this.$refs.Confirm.showDialog(`총 ${this.cancelChk.length}개의 락업을 종료 하시겠습니까?<br>락업 종료 시 복구가 불가능합니다.`, () => {
            this.cancelChk.forEach((item) => {
              formData.append('lockupIdxList[]', item)
            })
            this.$post(this.$LOCKUP_PLAN_CANCEL, 'lockup_cancel', formData, true, (result) => {
              this.selectCancel = false
              this.cancelChk = []
              let resultData = result.data
              for (let i = 0; i < resultData.length; i++) {
                if (resultData[i].result === 'success') {
                  let findData = this.items.find(e => e.lockupIdx === resultData[i].lockUpIdx)
                  this.successList.push(findData)
                } else {
                  let findData2 = this.items.find(e => e.lockupIdx === resultData[i].lockUpIdx)
                  this.failList.push(findData2)
                }
              }
              this.listState = true
              let page = this.$route.query.pagef
              if (typeof (page) === 'undefined') {
                page = 1
              }
              this.getList(page)
              this.notify('success', '락업이 종료되었습니다.')
            })
          }, '락업을 종료하겠습니다.')
        }
      }
    },

    trans(value) {
      if (value === 'LOCK') {
        return '진행중'
      } else if (value === 'PROCESSING') {
        return '지급중'
      } else if (value === 'CANCEL') {
        return '락업취소'
      } else if (value === 'DONE') {
        return '종료'
      } else if (value === 'SUSPEND') {
        return '지급중단'
      }
    },

    getLockupPlanDetail() {
      this.$get(`${this.$LOCKUP_PLAN}/${this.selectedPlanIdx}`, 'get_Plan_detail', true, (result) => {
        this.planDetail = result.data[0]
      }, (result) => {
        this.httpError(result)
      })
    },

    getList(page) {
      this.selectCancel = false
      this.selectSuspend = false
      this.cancelChk = []
      this.suspendChk = []
      if (this.page === undefined) this.page = 1
      this.$get(`${this.$LOCKUP_HISTORY_USER}?userIdx=${this.userIdx}&page=${page}&limit=${this.size}`, this.$options.name + '_getUserPlanList', true, (result) => {
        this.page = page
        this.total = result.total
        this.items = result.data
        this.processingList = []
        this.lockList = []
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i].status === 'PROCESSING') {
            this.processingList.push(this.items[i].lockupIdx)
          } else if (this.items[i].status === 'LOCK') {
            this.lockList.push(this.items[i].lockupIdx)
          }
        }
        try {
          this.$refs.Pagination.init(this.$options.name, {}, Number(this.page), this.total, Number(this.size))
        } catch (e) {
          console.log(e)
        }
      }, (result) => {
        console.log(result)
      })
    },

    initValue() {
      this.selectedPlanIdx = this.lockupPlans[0].lockupPlanIdx
      this.selectedRatio = this.lockupPlans[0].ratio
      this.shareAmount = this.calShareAmt(this.amtToLockup, this.selectedRatio)
    },

    calShareAmt(amt, ratio) {
      Big.DP = 9
      let amtBig = new Big(amt)
      let ratioBig = new Big(ratio)

      let shareAmt = amtBig.div(ratioBig)
      return shareAmt
    },

    selectLockupPlan(e) {
      this.lockupPlans.forEach(plan => {
        if (plan.lockupPlanIdx.toString() === e.target.value) {
          this.selectedRatio = plan.ratio
          this.shareAmount = this.calShareAmt(this.amtToLockup, this.selectedRatio)
        }
      })
    },

    openConfirm() {
      if (this.amtToLockup <= 0) {
        this.notify('error', '락업 수량은 양수만 입력 가능합니다.')
        return
      }
      Big.DP = 9
      let amtToLockupBig = new Big(this.amtToLockup)
      let oneBig = new Big(1)
      this.amtToLockup = amtToLockupBig.div(oneBig)
      this.amtToReward = this.amtToLockup * this.planDetail.rewardP / 100
      this.rewardShareAmount = this.amtToReward / (100 / this.planDetail.shareP)

      this.$refs.Confirm.showDialog(`락업수량: ${this.amtToLockup} / 락업지급수량: ${this.shareAmount}<br/>보상수량: ${this.amtToReward} / 보상지급수량: ${this.rewardShareAmount}`, () => {
        this.addUserLockupPlan()
      })
    },

    addUserLockupPlan() {
      if (!this.amtToLockup) {
        this.notify('error', '락업 수량을 입력해 주세요.')
        return
      }
      let formData = new FormData()
      formData.append('userIdx', this.userIdx)
      formData.append('lockUpAmount', this.amtToLockup)
      formData.append('lockUpShareAmount', this.shareAmount)
      formData.append('rewardAmount', this.amtToReward)
      formData.append('rewardShareAmount', this.rewardShareAmount)
      formData.append('lockupPlanIdx', this.selectedPlanIdx)
      formData.append('lockUpStartDate', this.selectedDate)

      this.$post(this.$LOCKUP_PLAN_ADD, this.$options.name + '_setData', formData, true, (result) => {
        this.notify('success', result.message)
        this.initValue()
        this.getList(1)
      }, (result) => {
        this.httpError(result)
      })
    },
  },

  computed: {
    checkedAllProcessing: {
      get: function () {
        if (this.suspendChk.length <= 0) {
          return
        }
        return this.processingList.length === this.suspendChk.length;
      },
      set: function (e) {
        this.suspendChk = e ? this.processingList : [];
      },
    },

    checkedAllLock: {
      get: function () {
        if (this.cancelChk.length <= 0) {
          return
        }
        return this.lockList.length === this.cancelChk.length;
      },
      set: function (e) {
        this.cancelChk = e ? this.lockList : [];
      },
    },
  },

  watch: {
    'selectedPlanIdx'() {
      this.getLockupPlanDetail()
    },

    'amtToLockup'() {
      if (!this.amtToLockup) {
        return
      }
      this.shareAmount = this.calShareAmt(this.amtToLockup, this.selectedRatio)
    },

    '$route.query.page'() {
      this.getList(this.$route.query.page)
      this.suspendChk = []
      this.cancelChk = []
    },
  }
}
</script>

<style>
input[type="date"] {
  position: relative;
}

input[type="date"]::-webkit-clear-button,
input[type="date"]::-webkit-inner-spin-button {
  display: none;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  color: transparent;
  cursor: pointer;
}

.input_placeholder {
  position: absolute;
  top: 16%;
  right: 4%;
}

.input_placeholder span {
  font-size: 14px;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 60vw;
  height: 50vh;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #d2d2d2;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  /*font-family: Helvetica, Arial, sans-serif;*/

}

.modal-header {
  border: none;
  padding: 0px;
  justify-content: end;
}

.modal-footer {
  border: none;
  justify-content: center;
}

.modal-body {
  margin: 20px;
  word-wrap: break-word;
  word-break: keep-all;
  font-size: 1.2rem;
  /*text-align: center;*/
  border: none;
  height: 60%;
}

.modal-default-button {
  text-align: center;
  border: none;
  border-radius: 5px;
  background: #b2b2b2;
  color: white;
  margin: 0px;
  padding: 10px;
  width: 200px;
}

@media (max-width: 240px) {
  .modal-body {
    margin: 0px;
  }

  .modal-default-button {
    text-align: center;
    border: none;
    border-radius: 5px;
    background: lightgray;
    color: white;
    margin: 0px;
    width: 50px;
    height: 30px;
    padding: 0px;
  }
}

.modal-default-button:hover {
  transition: all ease-in 0.1s 0s;
  background: gray;
  cursor: pointer;
}

.modal-default-button:active {
  position: relative;
  top: 2px;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.modal-body,
.modal {
  color: #666 !important;
}
</style>
