<template>
  <div style="padding: 20px">
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-4">
          <div class="col-sm-12">
            <h4 class="m-0">사용자</h4>
            <span> 데이터베이스에 기록된 사용자 목록을 확인합니다.</span>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-12">
            <div class="row">
              <h5 class="mr-2 mt-sm-2">필터</h5>
              <select class="form-control w-auto mr-1" v-model="selectedSearchType">
                <option value="NAME">이름</option>
                <option value="EMAIL">아이디</option>
                <option value="PHONE">휴대폰</option>
                <option value="ADDRESS">지갑주소</option>
              </select>
              <input type="text" class="form-control w-auto mr-1" placeholder="검색어" v-model="searchKeyword"
                     @keyup.enter="getList(1)">
              <button class="btn btn-primary" @click="getList(1)">검색</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-body">
                <table class="table table-bordered" id="excelData">
                  <thead>
                  <tr>
                    <th>아이디</th>
                    <th>이름</th>
                    <th>휴대폰</th>
                    <th>락업</th>
                    <th>스테이킹</th>
                    <th>사용 가능한 잔고</th>
                    <th>가입일</th>
                    <th>상세보기</th>
                    <th>락업플랜</th>
                  </tr>
                  </thead>
                  <tbody v-if="items.length > 0">
                  <tr v-for="item in items" :key="item.userIdx">
                    <td>{{ item.email }}</td>
                    <td>{{ item.name }}</td>
                    <td style="text-align: center">{{ item.phone }}</td>
                    <td style="text-align: right">{{ $numberWithCommasAndDecimal(item.lockupBalance) }}</td>
                    <td style="text-align: right">{{ $numberWithCommasAndDecimal(item.stakingBalance) }}</td>
                    <td style="text-align: right">{{ $numberWithCommasAndDecimal(item.availableBalance) }}</td>
                    <td style="text-align: center">{{ item.signupDate | moment('YYYY-MM-DD HH:mm:ss') }}</td>
                    <td class="text-center">
                      <span class="badge btn btn-outline-warning" @click="goToDetail(item)"
                            :class="{ 'disabled': item.userRole === 'ADMIN' }">상세보기</span>
                    </td>
                    <td class="text-center">
                      <span class="badge btn btn-outline-warning" @click="addUserLockupPlan(item)"
                            :class="{ 'disabled': item.userRole === 'ADMIN' }">상세보기</span>
                    </td>
                  </tr>
                  </tbody>
                  <tbody v-else>
                  <tr>
                    <td colspan="10" style="text-align: center;">관련된 내역이 없습니다.</td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer clearfix text-center row justify-content-center">
                <pagination ref="Pagination"></pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Pagination from "../common/Pagination"

export default {
  name: 'UserList',

  components: {
    Pagination,
  },

  data() {
    return {
      selectedSearchType: this.$route.params.st ? this.$route.params.st : 'NAME',
      searchKeyword: this.$route.params.sk ? this.$route.params.sk : '',
      size: '10',
      page: this.$route.params.page ? this.$route.params.page : 1,
      total: 0,
      items: [],
    }
  },

  created() {
    this.$root.$emit(this.$EVENT_LNB_CHANGE, 'user', this.$options.name)
  },

  mounted() {
    let page = this.$route.query.page
    if (typeof (page) === 'undefined') {
      page = 1
    }

    let size = this.$route.query.size
    if (typeof (size) !== 'undefined') {
      this.size = size
    }

    let st = this.$route.query.st
    if (typeof (st) !== 'undefined') {
      this.selectedSearchType = st
    }

    let sk = this.$route.query.sk
    if (typeof (sk) !== 'undefined') {
      this.searchKeyword = sk
    }

    this.getList(page)
    this.$router.push({
      query: {page: page, st: this.selectedSearchType},
    });
  },

  methods: {
    getList(page) {
      let params = new URLSearchParams()
      if (!page) page = 1

      params.append('page', page)
      params.append('limit', this.size)
      params.append('criteria', this.selectedSearchType)
      params.append('keyword', this.searchKeyword.trim())

      this.$get(`${this.$USER_LIST}?${params.toString()}`, this.$options.name + '_getList', true, (result) => {
        this.page = page
        this.total = result.total
        try {
          this.$refs.Pagination.init(this.$options.name, {
            st: this.selectedSearchType,
            sk: this.searchKeyword,
          }, Number(this.page), this.total, Number(this.size))
        } catch (e) {
          console.log(e)
        }
        this.$router.replace({
          query: {
            st: this.selectedSearchType,
            sk: this.searchKeyword,
            page: this.page,
            size: this.size,
          },
        });
        this.items = result.data
      }, (result) => {
        this.httpError(result)
      })
    },

    addUserLockupPlan(item) {
      if (item.userRole === 'ADMIN') {
        return
      }
      this.$router.push({
        name: 'UserAddLockupPlan',
        params: {
          userIdx: item.userIdx,
        }
      })
    },

    goToDetail(item) {
      if (item.userRole === 'ADMIN') {
        return
      }
      this.$router.push({
        name: 'UserDetail',
        params: {item: item, userIdx: item.userIdx}
      })
    },
  },

  watch: {
    '$route.query.page'() {
      this.getList(this.$route.query.page)
    },
  },
}
</script>

<style>
th {
  text-align: center;
}
</style>
