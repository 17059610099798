<template>
  <div style="padding: 20px;">
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-12">
            <h4 class="mb-2">락업 플랜 목록</h4>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-body">
                <table class="table table-bordered" id="excelData">
                  <thead>
                  <tr>
                    <th>NO</th>
                    <th>이름</th>
                    <th>락업기간 ( 일 )</th>
                    <th>지급비율 ( % )</th>
                    <th>생성일</th>
                    <th>상세보기</th>
                  </tr>
                  </thead>
                  <tbody v-if="items.length > 0">
                  <tr v-for="(item, index) in items" :key="item.lockupPlanIdx">
                    <td style="text-align: center">{{ index + 1 + (page - 1) * 10 }}</td>
                    <td>{{ item.name }}</td>
                    <td style="text-align: right">{{ item.period }}</td>
                    <td style="text-align: right">{{ $setDecimal(item.sharePerc, 1) }}</td>
                    <td style="text-align: center">{{ item.createdAt | moment('YYYY-MM-DD HH:mm:ss') }}</td>
                    <td class="text-center">
                      <span class="badge btn btn-outline-warning" @click="goToDetail(item)">상세보기</span>
                    </td>
                  </tr>
                  </tbody>
                  <tbody v-else>
                  <tr>
                    <td colspan="10" style="text-align: center;">관련된 내역이 없습니다.</td>
                  </tr>
                  </tbody>
                </table>
              </div><!-- ./card-body -->
              <div class="card-footer clearfix text-center row justify-content-center">
                <pagination ref="Pagination"></pagination>
              </div>
            </div><!-- /.card -->
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div>
    </section>
  </div>
</template>

<script>
import Pagination from "../common/Pagination"
import Big from "big-js"

export default {
  name: 'LockupPlanList',

  components: {
    Pagination,
  },

  data() {
    return {
      size: '10',
      page: 0,
      total: 0,
      items: [],
      limit: 10,
    }
  },

  created() {
    this.$root.$emit(this.$EVENT_LNB_CHANGE, 'lockupPlan', this.$options.name)

    let page = this.$route.query.page
    if (typeof (page) === 'undefined') {
      page = 1
    }

    let size = this.$route.query.size
    if (typeof (size) !== 'undefined') {
      this.size = size
    }

    this.getList(page)
  },

  methods: {
    goToDetail(item) {
      this.$router.push({
        name: 'LockupPlanDetail',
        params: {
          lockupPlanIdx: item.lockupPlanIdx
        }
      })
    },

    getList(page) {

      this.$get(`${this.$LOCKUP_PLAN_LIST}?page=${page}&limit=${this.limit}`, this.$options.name + '_getList', true, (result) => {
        this.page = page
        this.total = result.total

        try {
          this.$refs.Pagination.init(this.$options.name, {}, Number(this.page), this.total, Number(this.size))
        } catch (e) {
          console.log(e)
        }

        this.items = result.data

        this.items.forEach((item) => {
          Big.DP = 9
          let ratioBig = new Big(item.ratio)
          let ratio100 = new Big(100)

          item.sharePerc = ratio100.div(ratioBig)
        })
      }, (result) => {
        console.log(result)
      })
    },
  },

  watch: {
    '$route.query.page'() {
      this.getList(this.$route.query.page)
    },
  },
}
</script>

<style>
</style>
