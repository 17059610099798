<template>
  <div style="padding: 20px">
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-4">
          <div class="col-sm-12">
            <h4 class="m-0">스테이킹 플랜 등록</h4>
          </div>
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
              </div><!-- /.card-header -->
              <div class="card-body">
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>구분 *</strong>
                  </div>
                  <div class="col-sm-5">
                    <select class="form-control" v-model="stakingType">
                      <option value="FIX">고정이자</option>
                      <option value="EXTRA">추가보상</option>
                    </select>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>이름 *</strong>
                  </div>
                  <div class="col-sm-5">
                    <input type="text" class="form-control" placeholder="스테이킹 이름을 입력하세요." v-model="formData.name"/>
                  </div>
                </div>
                <div class="row align-items-center mb-2" v-if="stakingType === 'EXTRA'">
                  <div class="col-sm-3 text-right">
                    <strong><span style="margin-right: 10px">스테이킹</span><br>참여시작 *</strong>
                  </div>
                  <div class="col-sm-2">
                    <input type="date" class="form-control" v-model="participateStartDate"/>
                  </div>
                  <div class="col-sm-1 text-right">
                    <strong><span style="margin-right: 10px">스테이킹</span><br>참여종료 *</strong>
                  </div>
                  <div class="col-sm-2">
                    <input type="date" class="form-control" :min="participateStartDate" v-model="participateEndDate"/>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>스테이킹<br>시작 *</strong>
                  </div>
                  <div class="col-sm-2">
                    <input type="date" class="form-control" :min="today" :max="formData.stakingEndDate"
                           v-model="formData.stakingStartDate"/>
                  </div>
                  <div class="col-sm-1 text-right">
                    <strong>스테이킹<br>종료 *</strong>
                  </div>
                  <div class="col-sm-2">
                    <input type="date" class="form-control" :min="formData.stakingStartDate"
                           v-model="formData.stakingEndDate"/>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>최소수량 *</strong>
                  </div>
                  <div class="col-sm-2">
                    <input type="number" class="form-control" v-model="formData.minQuantity"/>
                  </div>
                  <div class="col-sm-1 text-right">
                    <strong>최대수량 *</strong>
                  </div>
                  <div class="col-sm-2">
                    <input type="number" class="form-control" v-model="formData.maxQuantity"/>
                  </div>
                </div>
                <div class="row align-items-center mb-2" v-if="stakingType==='FIX'">
                  <div class="col-sm-3 text-right">
                    <strong>보상비율(%) *</strong>
                  </div>
                  <div class="col-sm-2" v-if="stakingType==='FIX'">
                    <input type="number" class="form-control" v-model.number="formData.rewardPoint"
                           @input="handleInput"/>
                  </div>
                  <div class="col-sm-1 text-right">
                    <strong>일 보상비율</strong>
                  </div>
                  <div class="col-sm-2">
                    <input type="number" class="form-control" :value="dayRewardPoint" disabled
                           style="background: #6c757d;"/>
                  </div>
                </div>
                <div class="row align-items-center mb-2" v-if="stakingType==='EXTRA'">
                  <div class="col-sm-3 text-right">
                    <strong>총보상금액(원) *</strong>
                  </div>
                  <div class="col-sm-5" v-if="stakingType==='EXTRA'">
                    <input type="number" class="form-control" v-model="formData.rewardPoint"/>
                    <div class="input_placeholder">
                      <span>총보상금액은 추후 입력 및 수정이 가능합니다.</span>
                    </div>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>중복참여 *</strong>
                  </div>
                  <div class="col-sm-5">
                    <select class="form-control" v-model="formData.participateDuplicate">
                      <option value="true">중복참여 가능</option>
                      <option value="false">중복참여 불가능</option>
                    </select>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>내용</strong>
                  </div>
                  <div class="col-sm-5">
                    <textarea class="form-control" placeholder="300자 제한" rows="8" maxlength="300"
                              v-model="description"></textarea>
                  </div>
                </div>
              </div>
              <!-- ./card-body -->
              <div class="card-footer clearfix text-center row">
                <div class="col-sm-3">
                </div>
                <div class="col-sm-5">
                  <button type="button" class="btn btn-primary mr-1" @click="stakingRegister"
                          :disabled="accessBtn">등록
                  </button>
                </div>
              </div>
            </div><!-- /.card -->
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "StakingPlanRegister",

  data() {
    return {
      formData: {
        name: '',
        stakingStartDate: '',
        stakingEndDate: '',
        minQuantity: 0,
        maxQuantity: 0,
        rewardPoint: 0,
        participateDuplicate: true,
      },
      stakingType: 'FIX',
      participateStartDate: '',
      participateEndDate: '',
      description: '',
      today: '',
      dayRewardPoint: 0,
    }
  },

  created() {
    this.$root.$emit(this.$EVENT_LNB_CHANGE, 'stakingPlan', this.$options.name)
    let d = new Date()
    this.today = this.$dateFormat(d)
  },

  methods: {
    handleInput(e) {
      let stringValue = e.target.value.toString()
      let regex = /^\d*(\.\d{1,1})?$/
      if (!stringValue.match(regex) && this.formData.rewardPoint !== '') {
        this.formData.rewardPoint = this.$setDecimal(this.formData.rewardPoint, 10)
      }
    },

    getDateDiff(d1, d2) {
      const date1 = new Date(d1);
      const date2 = new Date(d2);
      const diffDate = date1.getTime() - date2.getTime();
      const dateResult = Math.floor(Math.abs(diffDate / (1000 * 60 * 60 * 24)) + 1)
      const result = Math.ceil(this.formData.rewardPoint / dateResult * 10000) / 10000
      this.dayRewardPoint = result; // 밀리세컨 * 초 * 분 * 시 = 일
    },

    resetData() {
      this.formData = {
        name: '',
        stakingStartDate: '',
        stakingEndDate: '',
        minQuantity: 0,
        maxQuantity: 0,
        rewardPoint: 0,
        participateDuplicate: true
      }
      this.participateStartDate = ''
      this.participateEndDate = ''
      this.description = ''
    },

    stakingRegister() {
      let formData = new FormData()
      let stop = false
      Object.entries(this.formData).forEach((entry) => {
        let [key, value] = entry
        if (!stop && value === '') {
          this.notify('error', '스테이킹 등록 정보를 정확히 입력해주세요.')
          stop = true
          return
        }
        formData.append(key, value)
      })
      if (this.description) {
        formData.append('description', this.description)
      }

      if (stop) {
        return
      }

      formData.append('type', this.stakingType)
      if (this.stakingType === 'EXTRA') {
        formData.append('participateStartDate', this.participateStartDate)
        formData.append('participateEndDate', this.participateEndDate)
      }

      this.$post(this.$STAKING_PLAN, this.$options.name + '_setData', formData, true, () => {
        this.notify('success', '등록되었습니다.')
        this.resetData()
      }, (result) => {
        this.httpError(result)
      })
    },
  },

  computed: {
    accessBtn() {
      if (
          !this.formData.name
          || !this.formData.stakingStartDate
          || !this.formData.stakingEndDate
          || this.formData.rewardPoint === ''
          || !this.formData.minQuantity
          || !this.formData.maxQuantity
      ) {
        return true;
      }
      if (this.stakingType === 'EXTRA' && !this.participateStartDate) {
        return true;
      }
      if (this.stakingType === 'EXTRA' && !this.participateEndDate) {
        return true;
      }
      return false;
    },
  },
  watch: {
    stakingType() {
      this.resetData()
    },

    formData: {
      handler() {
        if (this.formData.stakingStartDate && this.formData.stakingEndDate) {
          this.getDateDiff(this.formData.stakingStartDate, this.formData.stakingEndDate)
        }
      },
      deep: true,
    }
  }
}
</script>

<style scoped>
input[type="date"] {
  position: relative;
}

input[type="date"]::-webkit-clear-button,
input[type="date"]::-webkit-inner-spin-button {
  display: none;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  color: transparent;
  cursor: pointer;
}

.input_placeholder {
  position: absolute;
  top: 16%;
  right: 4%;
  color: rgb(157, 157, 157);
}
</style>