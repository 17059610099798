<template>
  <div class="m-3" v-if="userIdx">
    <div class="card-header border-bottom-0">
      <h5 class="card-title ml-5">회원정보</h5>
    </div>
    <div class="row align-items-center mb-2 flex-nowrap">
      <div class="col-sm-3 text-right">
        <strong>직권 송금</strong>
      </div>
      <div class="col-sm-6">
        <input type="number" class="form-control" v-model="amount" placeholder="금액 입력" @input="handleInput"/>
      </div>
      <button class="badge btn btn-primary ml-2" @click="userDeposit">입금</button>
      <button class="badge btn btn-primary ml-2" @click="userWithDraw">출금</button>
    </div>
    <div class="row align-items-center mb-2 flex-nowrap">
      <div class="col-sm-3 text-right">
        <strong>고유 번호</strong>
      </div>
      <div class="col-sm-6">
        <input type="text" class="form-control disabled" disabled :value="item ? item.userIdx: ''"
               style="background: #6c757d;"/>
      </div>
    </div>
    <div class="row align-items-center mb-2 flex-nowrap">
      <div class="col-sm-3 text-right">
        <strong>이름</strong>
      </div>
      <div class="col-sm-6">
        <input type="text" class="form-control" disabled :value="item.name" style="background: #6c757d;"/>
      </div>
    </div>
    <div class="row align-items-center mb-2 flex-nowrap">
      <div class="col-sm-3 text-right">
        <strong>아이디</strong>
      </div>
      <div class="col-sm-6">
        <input type="text" class="form-control" disabled :value="item.email" style="background: #6c757d;"/>
      </div>
    </div>
    <div class="row align-items-center mb-2 flex-nowrap">
      <div class="col-sm-3 text-right">
        <strong>지갑 주소</strong>
      </div>
      <div class="col-sm-6">
        <input type="text" class="form-control" disabled :value="item.address" :key="rerenderKey"
               style="background: #6c757d;"/>
      </div>
      <button class="badge btn btn-primary ml-2"
              @click="$router.push({ name: 'WalletList', params: { email: item.email } })">
        입출금 내역
      </button>
    </div>
    <div class="row align-items-center mb-2 flex-nowrap">
      <div class="col-sm-3 text-right">
        <strong>락업 잔고</strong>
      </div>
      <div class="col-sm-6">
        <input type="text" class="form-control" disabled :value="$numberWithCommasAndDecimal(item.lockUpBalance)"
               style="background: #6c757d;"/>
      </div>
      <button class="badge btn btn-primary ml-2"
              @click="$router.push({ name: 'LockupUser', params: { sk: item.email } })">
        락업 내역
      </button>
    </div>
    <div class="row align-items-center mb-2 flex-nowrap">
      <div class="col-sm-3 text-right">
        <strong>스테이킹 잔고</strong>
      </div>
      <div class="col-sm-6">
        <input type="text" class="form-control" disabled :value="$numberWithCommasAndDecimal(item.stakingBalance)"
               style="background: #6c757d;"/>
      </div>
      <button class="badge btn btn-primary ml-2"
              @click="$router.push({ name: 'StakingFixedUser', params: { sk: item.email } })">고정이자 내역
      </button>
      <button class="badge btn btn-primary ml-2"
              @click="$router.push({ name: 'StakingRewardUser', params: { sk: item.email } })">추가보상 내역
      </button>
    </div>
    <div class="row align-items-center mb-2 flex-nowrap">
      <div class="col-sm-3 text-right">
        <strong>사용 가능한 잔고</strong>
      </div>
      <div class="col-sm-6">
        <input type="text" class="form-control" disabled :value="$numberWithCommasAndDecimal(item.availableBalance)"
               style="background: #6c757d;"/>
      </div>
    </div>
    <div class="row align-items-center mb-2 flex-nowrap">
      <div class="col-sm-3 text-right">
        <strong>출금여부</strong>
      </div>
      <div class="col-sm-6">
        <span class="form-control" v-if="limitedAt" style="background: #6c757d;">가능</span>
        <span class="form-control" v-else style="background: #6c757d;">불가능</span>
      </div>
      <button class="badge btn btn-primary ml-2" :class="{'disabled':limitedAt}" @click="userLimit(false)">
        출금 허용
      </button>
      <button class="badge btn btn-primary ml-2" :class="{'disabled':!limitedAt}" @click="userLimit(true)">
        출금 제한
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserDetailInfo",

  props: [
    'userIdx'
  ],

  data() {
    return {
      rerenderKey: 0,
      amount: '',
      isIn: false,
      limitedAt: false,
      item: [],
    }
  },

  created() {
    if (this.userIdx) {
      this.getData()
    } else {
      this.$router.back()
    }
  },

  methods: {
    handleInput(e) {
      let stringValue = e.target.value.toString()
      let regex = /^\d*(\.\d{1,4})?$/
      if (!stringValue.match(regex) && this.amount !== '') {
        this.amount = this.$setDecimal(this.amount, 10000)
      }
    },

    userLimit(status) {
      let formData = new FormData()
      formData.append('idx', this.userIdx)
      formData.append('status', status)
      this.$post(this.$USER_LIMIT, 'user_limit', formData, true, () => {
        this.getData()
      }, (result) => {
        this.httpError(result)
      })
    },

    userDeposit() {
      if (this.amount <= 0) {
        this.notify('error', '양수만 입력 가능합니다.')
        return
      }
      this.isIn = true
      let formData = new FormData
      formData.append('isIn', this.isIn)
      formData.append('addressTo', this.item.address)
      formData.append('ticker', 'BUFF')
      formData.append('amount', this.amount)
      this.$post(this.$WALLET_DIRECT_REMITTANCE, 'user_deposit', formData, true, () => {
        this.item.availableBalance = parseFloat(this.item.availableBalance) + parseFloat(this.amount)
        this.amount = ''
        this.notify('success', '입금이 완료되었습니다.')
      }, (result) => {
        this.httpError(result)
      })
    },

    userWithDraw() {
      if (this.amount <= 0) {
        this.notify('error', '양수만 입력 가능합니다.')
        return
      }
      this.isIn = false
      let formData = new FormData
      formData.append('isIn', this.isIn)
      formData.append('addressTo', this.item.address)
      formData.append('ticker', 'BUFF')
      formData.append('amount', this.amount)
      this.$post(this.$WALLET_DIRECT_REMITTANCE, 'user_withdraw', formData, true, () => {
        this.item.availableBalance = parseFloat(this.item.availableBalance) - parseFloat(this.amount)
        this.amount = ''
        this.notify('success', '출금이 완료되었습니다.')
      }, (result) => {
        this.httpError(result)
      })
    },

    getData() {
      this.$get(`${this.$USER_LIST}/${this.userIdx}`, this.$options.name + '_getData', true, (result) => {
        this.item = result.data[0]
        if (result.data[0].limitedAt === null) {
          this.limitedAt = true
        } else {
          this.limitedAt = false
        }
        this.rerenderKey++
      }, (result) => {
        this.httpError(result)
      })
    },
  },
}
</script>

<style scoped>
</style>
