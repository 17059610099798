<template>
  <transition name="modal" v-if="show">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <h3 slot="header">
              <i class="fas fa-times close-modal-btn" style="cursor: pointer;" @click="no()"></i>
            </h3>
          </div>
          <div class="modal-body mt-1">
            <div slot="body" v-html="msg"></div>
            <div slot="body" v-if="msg2">
              <label class="mt-1">
                <input type="checkbox" v-model="chkConfirm">
                <span class="text-md ml-1">{{ msg2 }}</span>
              </label>
            </div>
          </div>
          <div class="modal-footer">
            <slot name="footer">
              <button class="modal-default-button mr-2" @click="ok()">
                확인
              </button>
              <button class="modal-default-button" @click="no()">
                취소
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "Confirm",

  data() {
    return {
      // isConfirm: true,
      show: false,
      msg: '',
      callback: null,
      msg2: '',
      chkConfirm: false
    }
  },

  methods: {
    showDialog(msg, callback, msg2) {
      this.msg = msg
      this.callback = callback
      this.show = true
      if (msg2) {
        this.msg2 = msg2
      }
    },

    ok() {
      if (!this.chkConfirm && this.msg2 !== '') {
        this.notify('error', '동의하시면 체크해 주세요.')
        return
      }
      this.callback()
      this.show = false
    },

    no() {
      this.show = false
      this.chkConfirm = false
    }
  }
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 999;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 60vw;
  height: 280px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #d2d2d2;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  /*font-family: Helvetica, Arial, sans-serif;*/

}

.modal-header {
  border: none;
  padding: 0px;
  justify-content: end;
}

.modal-footer {
  border: none;
  justify-content: center;
}

.modal-body {
  margin: 20px 0px;
  word-wrap: break-word;
  font-size: 1.2rem;
  text-align: center;
  border: none;
  height: 35%;
}

.modal-default-button {
  text-align: center;
  border: none;
  border-radius: 5px;
  background: #b2b2b2;
  color: white;
  margin: 0px;
  padding: 10px;
  width: 200px;
}

@media (max-width: 240px) {
  .modal-body {
    margin: 0px;
  }

  .modal-default-button {
    text-align: center;
    border: none;
    border-radius: 5px;
    background: lightgray;
    color: white;
    margin: 0px;
    width: 50px;
    height: 30px;
    padding: 0px;
  }
}

.modal-default-button:hover {
  transition: all ease-in 0.1s 0s;
  background: gray;
  cursor: pointer;
}

.modal-default-button:active {
  position: relative;
  top: 2px;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.modal-body,
.modal {
  color: #666 !important;
}
</style>
