<template>
  <div style="padding: 20px;">
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-12">
            <h4 class="mb-2">API 입출금 내역</h4>
          </div><!-- /.col -->
        </div><!-- /.row -->
        <div class="row mb-2">
          <div class="col-sm-12">
            <div class="row justify-content-between">
              <div class="row">
                <h5 class="mr-2 mt-sm-2 ml-2">필터</h5>
                <select class="form-control w-auto mr-1" v-model="selectedSearchType">
                  <option value="EMAIL">아이디</option>
                </select>
                <input type="text" class="form-control w-auto mr-1" placeholder="검색어" v-model="searchKeyword"
                       @keyup.enter="getList(1, selectedState)">
                <button class="btn btn-primary" @click="getList(1, selectedState)">검색</button>
              </div>
              <div>
                <button class="badge btn btn-default text-white p-2 pr-3 pl-3 border-secondary" style="border-radius: 0"
                        :class="{'btn-warning': selectedState === 'ALL'}" @click="searchStateBtn('ALL')">
                  전체
                </button>
                <button class="badge btn btn-default text-white p-2 pr-3 pl-3 border-secondary" style="border-radius: 0"
                        :class="{'btn-warning': selectedState === 'PAYMENT'}" @click="searchStateBtn('PAYMENT')">
                  상품결제
                </button>
                <button class="badge btn btn-default text-white p-2 pr-3 pl-3 border-secondary" style="border-radius: 0"
                        :class="{'btn-warning': selectedState === 'EVENT'}" @click="searchStateBtn('EVENT')">
                  이벤트 참여
                </button>
                <button class="badge btn btn-default text-white p-2 pr-3 pl-3 border-secondary" style="border-radius: 0"
                        :class="{'btn-warning': selectedState === 'SIGNUP'}" @click="searchStateBtn('SIGNUP')">
                  회원가입
                </button>
                <button class="badge btn btn-default text-white p-2 pr-3 pl-3 border-secondary" style="border-radius: 0"
                        :class="{'btn-warning': selectedState === 'POINT'}" @click="searchStateBtn('POINT')">
                  버프 → 캐시 교환
                </button>
                <button class="badge btn btn-default text-white p-2 pr-3 pl-3 border-secondary" style="border-radius: 0"
                        :class="{'btn-warning': selectedState === 'TOKEN'}" @click="searchStateBtn('TOKEN')">
                  캐시 → 버프 교환
                </button>
              </div>
            </div>
          </div>
        </div>
      </div><!-- /.container-fluid -->
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-body">
                <table class="table table-bordered" id="excelData">
                  <thead>
                  <tr>
                    <th>No</th>
                    <th>입출금 일시</th>
                    <th>아이디</th>
                    <th>API 호출</th>
                    <th>입금 수량</th>
                    <th>출금 수량</th>
                    <th>상세 내역</th>
                  </tr>
                  </thead>
                  <tbody v-if="items.length > 0">
                  <tr v-for="(item, index) in items" :key="item.stakingPlanIdx">
                    <td style="text-align: center">{{ index + 1 + (page - 1) * 10 }}</td>
                    <td style="text-align: center">{{ item.createdAt | moment('YYYY-MM-DD HH:mm:ss') }}</td>
                    <td style="text-align: left">{{ item.userId }}</td>
                    <td style="text-align: center">{{ trans(item.type) }}</td>
                    <td style="text-align: right">
                      <span v-if="item.increase">{{ item.amount }}</span>
                      <span v-else>0</span>
                    </td>
                    <td style="text-align: right">
                      <span v-if="!item.increase">{{ item.amount }}</span>
                      <span v-else>0</span>
                    </td>
                    <td class="text-center">
                      <span class="badge btn btn-outline-warning"
                            @click="$router.push({ name: 'WalletList', params: { email: item.userId } })">바로가기</span>
                    </td>
                  </tr>
                  </tbody>
                  <tbody v-else>
                  <tr>
                    <td colspan="11" style="text-align: center;">관련된 내역이 없습니다.</td>
                  </tr>
                  </tbody>
                </table>
              </div><!-- ./card-body -->
              <div class="card-footer clearfix text-center row justify-content-center">
                <div></div>
                <pagination ref="Pagination"></pagination>
              </div>
            </div><!-- /.card -->
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div>
    </section>
  </div>
</template>

<script>
import Pagination from "@/components/common/Pagination";

export default {
  name: "WalletHistoryBunny",

  components: {
    Pagination,
  },

  data() {
    return {
      selectedState: this.$route.params.ss ? this.$route.params.ss : 'ALL',
      selectedSearchType: this.$route.params.st ? this.$route.params.st : 'EMAIL',
      searchKeyword: this.$route.params.sk ? this.$route.params.sk : '',
      size: '10',
      page: this.$route.params.page ? this.$route.params.page : 1,
      total: 0,
      items: [],
      listState: false,
      selectClose: false,
      closeChk: [],
      closeList: [],
    }
  },

  created() {
    this.$root.$emit(this.$EVENT_LNB_CHANGE, 'wallet', this.$options.name)

    let page = this.$route.query.page
    if (typeof (page) === 'undefined') {
      page = 1
    }

    let size = this.$route.query.size
    if (typeof (size) !== 'undefined') {
      this.size = size
    }

    let ss = this.$route.query.ss
    if (typeof (ss) !== 'undefined') {
      this.selectedState = ss
    }

    let st = this.$route.query.st
    if (typeof (st) !== 'undefined') {
      this.selectedSearchType = st
    }

    let sk = this.$route.query.sk
    if (typeof (sk) !== 'undefined') {
      this.searchKeyword = sk
    }

    this.getList()
  },

  methods: {
    searchStateBtn(state) {
      this.$router.push({
        query: {page: 1, size: this.size, ss: state, st: this.selectedSearchType},
      });
      this.selectedState = state
      this.getList(1, state)
    },

    trans(value) {
      if (value === 'PAYMENT') {
        return '상품결제'
      } else if (value === 'SIGNUP') {
        return '회원가입'
      } else if (value === 'EVENT') {
        return '이벤트 참여'
      } else if (value === 'POINT') {
        return '버프 → 캐시 교환'
      } else if (value === 'TOKEN') {
        return '캐시 → 버프 교환'
      }
    },

    goToDetail(planId) {
      this.$router.push({
        name: 'StakingPlanDetail',
        params: {planId: planId}
      })
    },

    getList(page, state) {
      let params = new URLSearchParams()
      if (typeof page === 'undefined') page = 1
      if (page === 1) this.page = 1
      params.append('page', page)
      params.append('limit', this.size)
      if (state) {
        if (state === 'ALL') {
          this.selectedState = state
        } else {
          this.selectedState = state
          params.append('type', this.selectedState)
        }
      }
      params.append('criteria', this.selectedSearchType)
      params.append('keyword', this.searchKeyword.trim())
      this.$get(this.$WALLET_HISTORY_BUNNY + '?' + params.toString(), this.$options.name + '_getList', true, (result) => {
        this.page = page
        this.total = result.total

        try {
          this.$refs.Pagination.init(this.$options.name, {
            ss: this.selectedState,
            st: this.selectedSearchType,
            sk: this.searchKeyword,
          }, Number(this.page), this.total, Number(this.size))
        } catch (e) {
          console.log(e)
        }
        this.$router.replace({
          query: {
            ss: this.selectedState,
            st: this.selectedSearchType,
            sk: this.searchKeyword,
            page: this.page,
            size: this.size,
          },
        });

        this.items = result.data
      }, (result) => {
        this.httpError(result)
      })
    },
  },

  computed: {
    checkedAllClose: {
      get: function () {
        return this.closeList.length === this.closeChk.length;
      },
      set: function (e) {
        this.closeChk = e ? this.closeList : [];
      },
    },
  },

  watch: {
    '$route.query.page'() {
      this.getList(this.$route.query.page, this.$route.query.ss)
    },
  },
}
</script>

<style scoped>

</style>