<template>
  <div style="padding: 20px;">
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-12">
            <h4 class="mb-2">스테이킹 이용약관</h4>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-body">
                <table class="table table-bordered" id="excelData">
                  <thead>
                  <tr>
                    <th>약관</th>
                    <th>등록날짜</th>
                    <th>개정날짜</th>
                    <th>상태</th>
                  </tr>
                  </thead>
                  <tbody v-if="items.length > 0">
                  <tr v-for="item in items" :key="item.lockupPlanIdx">
                    <td>
                      <span @click="goToDetail(item)" style="cursor: pointer; text-decoration: underline;">
                        {{ item.title }}
                      </span>
                    </td>
                    <td style="text-align: center">{{ item.createdAt | moment('YYYY-MM-DD') }}</td>
                    <td style="text-align: center">{{ item.revisionDate | moment('YYYY-MM-DD') }}</td>
                    <td style="text-align: center">
                      <span :class="{'text-orange':item.state==='RESERVED'}">{{ trans(item.state) }}</span>
                    </td>
                  </tr>
                  </tbody>
                  <tbody v-else>
                  <tr>
                    <td colspan="10" style="text-align: center;">관련된 내역이 없습니다.</td>
                  </tr>
                  </tbody>
                </table>
              </div><!-- ./card-body -->
              <div class="row justify-content-between mr-3 ml-3 mb-4">
                <div></div>
                <pagination ref="Pagination"></pagination>
                <button class="btn btn-sm btn-warning text-white mr-2"
                        @click="$router.push({ name : 'TermsRegister' })">약관 등록
                </button>
              </div>
            </div><!-- /.card -->
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div>
    </section>
  </div>
</template>

<script>
import Pagination from "@/components/common/Pagination";

export default {
  name: "TermsList",

  components: {
    Pagination,
  },

  data() {
    return {
      size: '10',
      page: 0,
      total: 0,
      items: [],
    }
  },

  created() {
    this.$root.$emit(this.$EVENT_LNB_CHANGE, 'setting', this.$options.name)

    let page = this.$route.query.page
    if (typeof (page) === 'undefined') {
      page = 1
    }

    let size = this.$route.query.size
    if (typeof (size) !== 'undefined') {
      this.size = size
    }

    this.getList(page)
  },

  methods: {
    trans(value) {
      if (value === 'RESERVED') {
        return '예약중'
      } else if (value === 'REGISTER') {
        return '등록'
      } else if (value === 'PROCESSING') {
        return '등록'
      } else if (value === 'CANCEL') {
        return '취소'
      }
    },

    goToDetail(item) {
      this.$router.push({
        name: 'TermsDetail',
        params: {
          idx: item.termsIdx
        }
      })
    },

    getList(page) {

      this.$get(`${this.$TERMS}?page=${page}&limit=${this.size}`, this.$options.name + '_getList', true, (result) => {
        this.page = page
        this.total = result.total

        try {
          this.$refs.Pagination.init(this.$options.name, {}, Number(this.page), this.total, Number(this.size))
        } catch (e) {
          console.log(e)
        }

        this.items = result.data

      }, (result) => {
        console.log(result)
      })
    },
  },

  watch: {
    '$route.query.page'() {
      this.getList(this.$route.query.page)
    },
  },
}
</script>

<style scoped>

</style>