<template>
  <div class="logo-wrap">
    <img src="dist/img/logo_disable.svg" style="width: 300px"/>
  </div>
</template>

<script>
export default {
  name: 'Dashboard',

  created() {
    this.$get(this.$LOCKUP_PLAN_FEE_INFO, this.$options.name + '_setData', true, (result) => {
      let decimalView = result.data[0].length
      sessionStorage.setItem('decimalView', decimalView)
    }, (result) => {
      this.httpError(result)
    })
  }
}
</script>

<style>
.logo-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 880px;
}

</style>
