import Vue from 'vue'
import App from './App.vue'
import router from './router'

router.beforeEach((to, from, next) => {
  if(!to.matched.length) {
    router.push({ name: 'Login' })
  }
  next()
})

Vue.config.productionTip = false

import Vuex from 'vuex'
Vue.use(Vuex)

import vueMoment from 'vue-moment'
Vue.use(vueMoment)

import Notifications from 'vue-notification'
Vue.use(Notifications)

import api from './commonjs/api'
Vue.use(api)

import common from "@/commonjs/common"
Vue.use(common)

import event from './commonjs/event'
Vue.use(event)

new Vue({
  render: h => h(App),
  router,
}).$mount('#app')
