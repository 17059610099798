<template>
  <!-- Preloader -->
  <div class="preloader flex-column justify-content-center align-items-center">
    <img class="animation__wobble" src="dist/img/intro.svg" alt="AdminLTELogo" height="60" width="60">
  </div>
</template>

<script>
export default {
  name: 'Intro',
  created() {
    setTimeout(() => {
      let token = sessionStorage.getItem('token')
      if (token) {
        this.$router.replace({name: 'Dashboard'})
      } else {
        this.$router.replace({name: 'Login'})
      }
    }, 1000)
  }
}
</script>

<style>
</style>
